import * as React from 'react';
import {
  Modal as ShopifyModal,
  Tabs,
  Stack,
  Button,
  Thumbnail,
  TextStyle,
  DisplayText,
  Banner,
  Link,
} from '@shopify/polaris';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ModalContext } from '../../providers/ModalContext';
import Dropzone, { IFileWithMeta } from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import { useRef } from 'react';
import FilePreviewLayout from './PreviewLayout';
import { ToastContext } from '../../providers/ToastContext';
import FileLayout from './FileLayout';
import ButtonLayout from './ButtonLayout';
import { SheetContext } from '../../providers/SheetContext';
import useSWR from 'swr';
import fetcher from '../../lib/fetcher';
import { File } from '../../models/file';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIconByMediaType, isValidExtension } from '../../utils/media';
import { PlanContext } from '../../providers/UpgradePlanContext';
import { useRouter } from 'next/router';

interface UploadMediaProps {
  visible: boolean;
}

const UploadMediaModal: React.FC<UploadMediaProps> = ({ visible }) => {
  const { modal, hide } = useContext(ModalContext);

  const [tabs, setTabs] = useState([
    {
      id: 'my-device',
      content: 'My device',
    },
  ]);
  const { show } = useContext(ToastContext);
  const { show: showSheet } = useContext(SheetContext);
  const inputFileRef = useRef(null);
  const [selected, setSelected] = useState(0);
  const { plan } = useContext(PlanContext);
  const router = useRouter();
  const { data: uploadedFiles } = useSWR<any>(
    `${process.env.APP_URL}/files?limit=500&status=done${modal.type ? `&type=${modal.type}` : ''}`,
    fetcher,
  );

  useEffect(() => {
    if (modal.accept && uploadedFiles && uploadedFiles.length > 0 && tabs.length === 1) {
      setTabs([
        ...tabs,
        {
          id: 'uploaded',
          content: 'Uploaded',
        },
      ]);
      setSelected(1);
    }
  });

  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

  const openProPlan = () => {
    hide();
    router.push('/plans');
  };

  const InputLayout = ({ accept, onFiles, getFilesFromEvent, files }) => {
    return (
      <div
        style={{
          minHeight: files.length === 0 ? 450 : 0,
          justifyContent: 'center',
          display: 'flex',
          padding: 20,
        }}
      >
        <Stack vertical alignment={'center'} distribution={'center'}>
          <Stack.Item>
            <DisplayText size={'small'}>Upload files from your computer</DisplayText>
          </Stack.Item>
          <Stack.Item>
            <TextStyle variation={'subdued'}>Drag & Drop files and/or directories here</TextStyle>
          </Stack.Item>
          <Stack.Item>
            <Button primary onClick={() => inputFileRef.current.click()}>
              Add files
            </Button>
            <input
              id="myInput"
              type="file"
              ref={inputFileRef}
              accept={accept}
              multiple={plan.currentPlan.bulkUpload.isEnabled}
              style={{ display: 'none' }}
              onChange={(e) => {
                getFilesFromEvent(e).then((chosenFiles) => {
                  onFiles(chosenFiles);
                });
              }}
            />
          </Stack.Item>
        </Stack>
        {plan.currentPlan.name !== 'Pro' && (
          <div style={{ position: 'absolute', bottom: '20px' }}>
            <Banner status="info">
              <p>
                Want to upload multiple files or entire directories? Upgrade to{' '}
                <Link onClick={() => openProPlan()}>
                  <b>Pro</b> plan
                </Link>
                .
              </p>
            </Banner>
          </div>
        )}
      </div>
    );
  };

  const fileMarkup = (files: File[]) => {
    return files.map((file, index) => {
      const title = `${file.metadata.name || '?'}`;
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 10,
            paddingBottom: 10,
            borderBottom: files.length - 1 === index ? 0 : 1,
            borderBottomColor: '#dfe3e8',
            borderBottomStyle: 'solid',
          }}
        >
          <Stack alignment={'center'}>
            <Stack.Item>
              {file.type === 'image' && (
                <Thumbnail
                  alt="Image preview"
                  source={file.storage.assets.thumbnail.secure_url}
                  size={'small'}
                />
              )}
              {file.type !== 'image' && (
                <div style={{ width: 20 }}>
                  <FontAwesomeIcon
                    icon={getIconByMediaType(file.metadata.type)}
                    size={'lg'}
                    color={'#6371c7'}
                  />
                </div>
              )}
            </Stack.Item>
            <Stack.Item fill>
              <p
                style={{
                  width: 250,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {title}
              </p>
            </Stack.Item>
            <Stack.Item>
              {file.status === 'done' && (
                <Button
                  size={'slim'}
                  primary
                  onClick={() => {
                    modal.primaryAction.onAction(file);
                    hide();
                  }}
                >
                  Select
                </Button>
              )}
            </Stack.Item>
          </Stack>
        </div>
      );
    });
  };

  const handleSubmit = (files: IFileWithMeta[]) => {
    files.forEach((f) => f.restart());
  };

  const handleChangeStatus = (_file: IFileWithMeta, _status: string, files: IFileWithMeta[]) => {
    const isDone = files.filter((file) => file.meta.status === 'done').length === files.length;
    if (isDone) {
      showSheet({
        newUploads: files.map((file) => {
          return {
            status: 'pending',
            metadata: file.meta,
          };
        }),
      });
      hide();
    }
  };
  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  const getUploadParams = async ({
    file,
    remove,
    meta: { name, type, size, lastModifiedDate, duration = null },
  }) => {
    const request = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('tuneboomAccessToken')}`,
      },
      body: JSON.stringify({ name, type, size, lastModifiedDate, duration }),
    };

    const result = await fetch(`${process.env.APP_URL}/files`, request);

    if (result.status !== 201) {
      const body = await result.json();
      show({ message: body.message, isError: true });
      remove();
      return Promise.reject(body.message);
    }
    const { url, fields, method, headers } = await result.json();
    return { url, fields, method, body: file, headers };
  };

  const validateFile = (file: IFileWithMeta): string => {
    if (!isValidExtension(file.file.name)) {
      return 'invalid file type';
    }
    return;
  };

  const uploadZone = (
    <Dropzone
      accept={modal.accept ? modal.accept : '*'}
      getUploadParams={getUploadParams}
      onSubmit={handleSubmit}
      validate={validateFile}
      LayoutComponent={FileLayout}
      autoUpload={false}
      InputComponent={InputLayout}
      onChangeStatus={handleChangeStatus}
      multiple={plan.currentPlan.bulkUpload.isEnabled}
      PreviewComponent={FilePreviewLayout}
      styles={{
        preview: {
          padding: 0,
        },
        dropzone: {
          border: '0',
          overflow: 'unset',
          justifyContent: 'center',
        },
      }}
      SubmitButtonComponent={ButtonLayout}
      maxFiles={plan.currentPlan.bulkUpload.isEnabled ? 20 : 1}
      maxSizeBytes={plan.currentPlan.files.sizeLimit * 1000000} // convert to bytes's
      inputContent="Upload files from your computer"
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      getFilesFromEvent={getFilesFromEvent}
    />
  );

  return (
    <ShopifyModal
      open={visible}
      onClose={() => {
        hide();
      }}
    >
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}></Tabs>
      {selected === 0 && <ShopifyModal.Section>{uploadZone}</ShopifyModal.Section>}
      {selected === 1 && (
        <div style={{ minHeight: 470 }}>
          <ShopifyModal.Section>{fileMarkup(uploadedFiles)}</ShopifyModal.Section>
        </div>
      )}
    </ShopifyModal>
  );
};

export default UploadMediaModal;
