import * as React from 'react';
import { Button } from '@shopify/polaris';
import { IFileWithMeta, ISubmitButtonProps } from 'react-dropzone-uploader';

const ButtonLayout: React.FC<ISubmitButtonProps> = ({ onSubmit, files, extra }) => {
  const clearAll = (files: IFileWithMeta[]) => {
    files.forEach((file) => {
      file.remove();
    });
  };
  const getIncompleteFiles = (files: IFileWithMeta[]) => {
    return files.filter((file) => file.meta.status !== 'done');
  };

  const isUploading = (files: IFileWithMeta[]) => {
    return files.filter((file) => file.meta.status === 'uploading').length > 0;
  };

  if (files.length === 0) return null;
  return (
    <div
      style={{
        alignItems: 'center',
        borderTop: '1px solid #DFE3E8',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1.6rem',
        paddingBottom: '0',
        marginLeft: '-2rem',
        marginRight: '-2rem',
      }}
    >
      <Button onClick={() => clearAll(files)}>Deselect All</Button>
      <Button
        primary
        loading={isUploading(files)}
        onClick={() => onSubmit(getIncompleteFiles(files))}
        disabled={files.length > extra.maxFiles}
      >
        Upload ({files.length.toString()})
      </Button>
    </div>
  );
};

export default ButtonLayout;
