import * as React from 'react';
import { ILayoutProps } from 'react-dropzone-uploader';

const FileLayout: React.FC<ILayoutProps> = ({
  input,
  previews,
  dropzoneProps,
  files,
  extra: { maxFiles },
  submitButton,
}) => {
  return (
    <div>
      {previews}

      <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
      {submitButton}
    </div>
  );
};

export default FileLayout;
