import React, { createContext, useState, useCallback } from 'react';

const initialSheet = {
  title: '',
  content: '',
  primaryAction: null,
  secondaryAction: {
    content: 'Cancel',
  },
  isError: false,
  loading: false,
  visible: false,
  newUploads: [],
  type: null,
  defaultTab: 0,
};

export const SheetContext = createContext(null);

export const SheetProvider = ({ children }) => {
  const [sheet, setSheet] = useState(initialSheet);

  const clearUploads = useCallback(() => {
    setSheet((prevState) => ({
      ...prevState,
      newUploads: [],
    }));
  }, []);

  const show = useCallback(
    (args) => {
      setSheet({ ...initialSheet, visible: true, ...args });
    },
    [sheet],
  );

  const loading = useCallback((val) => {
    setSheet((prevState) => ({
      ...prevState,
      loading: val,
    }));
  }, []);

  const hide = useCallback(() => {
    setSheet({ ...sheet, visible: false });
  }, [sheet]);

  return (
    <SheetContext.Provider
      value={{
        hide,
        show,
        loading,
        sheet,
        clearUploads,
      }}
    >
      {children}
    </SheetContext.Provider>
  );
};
