import * as React from 'react';
import { formatDuration, IPreviewProps } from 'react-dropzone-uploader';
import { Button, Stack, Tooltip } from '@shopify/polaris';
import { CancelSmallMinor, TickMinor, RedoMajor } from '@shopify/polaris-icons';
import { faFileArchive, faImage, faMusic, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getIconByMediaType = (type) => {
  switch (type) {
    case 'audio/wav':
    case 'audio/mpeg':
    case 'audio/flac':
    case 'audio/x-wav':
      return faMusic;
    case 'image/jpeg':
    case 'image/gif':
    case 'image/png':
      return faImage;
    case 'application/zip':
    case 'application/x-7z-compressed':
    case 'application/vnd.rar':
    case 'application/x-rar-compressed':
    case 'application/x-rar':
      return faFileArchive;
    default:
      return faFile;
  }
};

const FilePreviewLayout: React.FC<IPreviewProps> = (props) => {
  const {
    className,
    style,
    fileWithMeta: { cancel, remove, restart },
    meta: { name = '', size = 0, status, duration, validationError, type, percent },
    canCancel,
    canRemove,
    canRestart,
    extra: { minSizeBytes },
  } = props;
  let title = `${name || '?'}`;
  if (duration) title = `${title}, ${formatDuration(duration)}`;

  if (status === 'error_file_size' || status === 'error_validation') {
    return (
      <div className={className} style={style}>
        <span className="dzu-previewFileNameError">{title}</span>
        {status === 'error_file_size' && (
          <span>{size < minSizeBytes ? 'File too small' : 'File too big'}</span>
        )}
        {status === 'error_validation' && <span>{String(validationError)}</span>}
        {canRemove && <Button plain icon={CancelSmallMinor} onClick={remove} />}
      </div>
    );
  }

  if (
    status === 'error_upload_params' ||
    status === 'exception_upload' ||
    status === 'error_upload'
  ) {
    title = `${title} (upload failed)`;
  }
  if (status === 'aborted') title = `${title} (cancelled)`;

  return (
    <div
      style={{
        paddingTop: 15.6,
        paddingBottom: 10,
        borderBottom: 1,
        borderBottomColor: '#dfe3e8',
        borderBottomStyle: 'solid',
      }}
    >
      <Stack>
        <Stack.Item>
          <div style={{ width: 20 }}>
            <FontAwesomeIcon icon={getIconByMediaType(type)} size={'lg'} color={'#6371c7'} />
          </div>
        </Stack.Item>
        <Stack.Item fill>
          <p
            style={{
              maxWidth: 350,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {title}
          </p>
        </Stack.Item>
        <Stack.Item>
          <progress value={percent} />
        </Stack.Item>
        <Stack.Item>
          {status === 'uploading' && canCancel && (
            <Button plain icon={CancelSmallMinor} onClick={cancel} />
          )}
          {status === 'done' && (
            <Tooltip content={'Download complete'} preferredPosition={'above'}>
              <Button plain icon={TickMinor} />
            </Tooltip>
          )}
          {['error_upload_params', 'exception_upload', 'error_upload', 'aborted'].includes(
            status,
          ) &&
            canRestart && <Button plain icon={RedoMajor} onClick={restart} />}
          {status !== 'preparing' &&
            status !== 'getting_upload_params' &&
            status !== 'uploading' &&
            status !== 'done' &&
            canRemove && <Button plain icon={CancelSmallMinor} onClick={remove} />}
        </Stack.Item>
      </Stack>
    </div>
  );
};

export default FilePreviewLayout;
